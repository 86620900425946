import { prodapiAxiosClient } from "./prodApiClient";

const getCmConfigs = () => {
    return prodapiAxiosClient
    .get('/api/v1/cmConfig') 
}

const addCmConfig = (cmConfig) => {
    return prodapiAxiosClient
    .post('/api/v1/cmConfig', cmConfig);
}

const updateCmConfig = (cmConfigId, cmConfig) => {
    return prodapiAxiosClient
    .post(`/api/v1/cmConfig/${cmConfigId}`, cmConfig);
}

const deleteCmConfig = (cmConfigId) => {
    return prodapiAxiosClient
    .delete(`/api/v1/cmConfig/${cmConfigId}`);
}

const cmConfigApi = { getCmConfigs, addCmConfig, updateCmConfig, deleteCmConfig };
export default cmConfigApi;