import React, { useEffect, useState } from "react";
import { Row, Col, Switch } from "react-materialize";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { getProductionTesting } from '../../utils/prodlocalhelper';
import classnames from "classnames";
import cameraApi from "../../api/cameraApi";
import cameraModelApi from "../../api/cameraModelApi";
import productTestingApi from "../../api/productTestingApi";
import { isLocalSDCardReady } from '../../utils/prodlocalhelper';
import LabelTest from "../../components/material/LabelTest";
import ReactTableBuilder from "../../utils/reactTableBuilder";
import plantApi from "../../api/plantApi";
import jwt_decode from "jwt-decode";

function ProductionTesting (props) {
    const { t } = props;
    const [cameraInfoScanned, setCameraInfoScanned] = useState(undefined)
    const [scanner, setScanner] = useState("");
    const [error, setError] = useState("");
    const [sdCardFound, setSdCardFound] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [textBoxVariable, setTextBoxVariable] = useState([]);
    const [testPass, setTestPass] = useState(false);
    const [tests, setTests] = useState([])
    const [selectedTest, setSelectedTest] = useState(undefined)
    const [formattedTest, setFormattedTest] = useState(undefined)
    const [logFileData, setLogFileData] = useState([])
    const [cellFileInfo, setCellFileInfo] = useState([])
    const [logFileInfo, setLogFileInfo] = useState([])
    const [cellFileFallbackInfo, setCellFileFallbackInfo] = useState([])
    const [logFileFallbackInfo, setLogFileFallbackInfo] = useState([])
    const [renderNewTest, setRenderNewTest] = useState(false)
    const [lastSelectedTest, setLastSelectedTest] = useState(undefined)
    const [datamatrixBypass, setDatamatrixBypass] = useState(false)

    const getDatamatrixModelTest = () => (
    {
        false: "Datamatrix doesn't match",
        id: "datamatrix_model",
        toTest: true,
        true:"Datamatrix OK"
    });

    /**
     * Retrieves all the tests and checks if the SD card can be read
     */
    useEffect(() => {
        productTestingApi.getAllProdutionTesting()
        .then(res => {
            setTests(res.data)
        })
        .catch(err => {
            console.log(err)
        })

        isLocalSDCardReady((err, res) => {
            if (err) {
              console.log(err)
            } else {
              setSdCardFound(res.data)
            }
          });
    }, []);

    /**
     * This creates the icons for pass and fail for all the regex in the selected test
     */
    useEffect(() => {
        let textBox = []
        let testPass = true
        if (formattedTest && formattedTest.length !== 0) {
            formattedTest.regex.map((regex) =>
                textBox.push({ id: regex.name, toTest: verifyRegexPassed(regex), true: regex.successMessage, false: regex.errorMessage })
            )

            //HARD CODED TEST for datamatrix
            const datamatrixTest = getDatamatrixModelTest();
            const datamatrixParts = scanner.split(",");
            let cameraModel = ""
            if (scanner.split(/,|;/).length === 4) {
                cameraModel = scanner.split(/,|;/)[3]
            }

            // datamatrix that are shorter that 45 characters have commas added to it so we remove them before comparing with commonName
            cameraModel = cameraModel.replace(/-*$/, '');

            if(cameraInfoScanned.commonName !== cameraModel)
            {
                datamatrixTest.toTest = datamatrixBypass ? 1 : false;
                datamatrixTest.false=`Inconsistency, Datamatrix : ${cameraModel}, Database: ${cameraInfoScanned.commonName}`;
            }

            setLogFileInfo(prevLogFileInfo => {
                prevLogFileInfo.push(
                    {
                        info:datamatrixTest.toTest && datamatrixTest.toTest !== 1 ? datamatrixTest.true : datamatrixTest.false,
                        original:scanner,
                        status:datamatrixTest.toTest && datamatrixTest.toTest !== 1 ? "ok":"error"
                    }
                )
                return prevLogFileInfo;
            });

            textBox.push(datamatrixTest)

            setTextBoxVariable(textBox)
            textBox.forEach(areAllTrue => {
                if(datamatrixBypass && areAllTrue.id==="datamatrix_model")
                    return;

                if (!areAllTrue.toTest || areAllTrue.totest === "0") {

                    testPass = false
                }
            })
            // Save the test automatically when it fails
            if (!testPass) {
                onClickPutCamera("FAILED")
            } else {
                onClickPutCamera("PASS")
            }
            setTestPass(testPass)
            setScanner("")
            document.getElementById("scanner").select()
        } else {
            setTextBoxVariable([])
            setButtonDisabled(true)
        }
    }, [renderNewTest])

    /**
     * Gets the data from the SD card and then calls a callback function
     */
    useEffect(() => {
        if (cameraInfoScanned && selectedTest) {

            //Validate that camera belongs to test!
            if(!selectedTest.cameraModels.includes(cameraInfoScanned.model))
                clearPartialData();
            else
                getProductionTesting("test", callback)
        }
    }, [cameraInfoScanned, selectedTest])

    function getUserPlant() {
        const decoded = jwt_decode(localStorage.getItem("jwtToken"));
        return plantApi.getPlant(decoded.user.plant).then(res => {
          return res.data;
        })
    }

    /**
     * Seperates the line of log in a dateTime and the line information
     */
    function processLine(info, status) {
        const infoFormat = info.substr(info.indexOf(']') + 1);
        const dateTime = info.substr(info.indexOf('[') + 1, info.indexOf(']') - 1 - info.indexOf('['));
        return { dateTime, info: infoFormat, original: info, status }
    }

    /**
     * Formats the logs found by the regex to fit in the react-table
     * Also adds all the fallback lines found that aren't already in the fileInfo
     * @param fileInfo the file information to format
     * @param fallbackFileInfo the information from the fallback regex to check if we need to add them to the table
     * @returns the formated data
     */
    function formatLogToTable(fileInfo, fallbackFileInfo) {
        let dataFormat = [];
        if (fallbackFileInfo) {
            fallbackFileInfo.forEach(lineInfo => {
                if (!fileInfo.includes(lineInfo.info)) {
                    dataFormat.push(processLine(lineInfo.info, lineInfo.severe ? "error" : "warning"));
                }
            });
        }

        if (fileInfo) {
            fileInfo.forEach(info => dataFormat.push(processLine(info, "ok")));
        }
        return dataFormat;
    }

    /**
     * This function creates a Regular Expression object from a string
     *
     * @param {string} regexString - The string representation of the regular expression
     * The string should be in the format '/pattern/flags'
     *
     * @returns {RegExp} - The RegExp object created from the given string
     */
    function createRegExp(regexString) {
        const pattern = regexString.slice(1, regexString.lastIndexOf("/"));
        const flags = regexString.slice(regexString.lastIndexOf("/") + 1);
        return new RegExp(pattern, flags);
    }

    /**
     * This function updates the log file data and file info based on the line that was found in the file
     *
     * @param regex - The regex object
     * @param {string} line - The line that was found in the file
     * @param {string} type - The type of file the log is from
     */
    function lineFound(regex, line, type) {
        if (logFileData[regex.name]) {
            if (Array.isArray(logFileData[regex.name])) {
                logFileData[regex.name] = [...logFileData[regex.name], true]
            } else {
                logFileData[regex.name] = [logFileData[regex.name], true]
            }
        } else {
            logFileData[regex.name] = true;
        }
        setLogFileData(logFileData);

        if (type === "cell") {
            cellFileInfo.push(line);
            setCellFileInfo(cellFileInfo);
        } else {
            logFileInfo.push(line);
            setLogFileInfo(logFileInfo);
        }
    }

    /**
     * This function checks a line of the log file to see if there is one of the regex that matches this line
     *
     * @param regex - An object containing one or more regex and a namecom
     * The regex in the object are strings in the format '/pattern/flags'.
     *
     * @param {string} line - The line of text from the log file
     * @param {string} type - The type of file the log is from
     */
    function getFileInfo(regex, line, type) {
        if (Array.isArray(regex.regex)) {
            const regexp = createRegExp(regex.regex[0])
            const regexp2 = createRegExp(regex.regex[1])
            if (line.match(regexp) || line.match(regexp2)) {
                lineFound(regex, line, type)
                return;
            }
            const fallbackRegexp = createRegExp(regex.fallbackRegex)
            if (line.match(fallbackRegexp)) {
                if (logFileData[regex.name]) {
                    logFileData[regex.name] = Array.isArray(logFileData[regex.name])
                        ? [...logFileData[regex.name], false]
                        : [logFileData[regex.name], false];
                } else {
                    logFileData[regex.name] = false;
                }
            }
        } else {
            const regexp = createRegExp(regex.regex)
            if (line.match(regexp)) {
                lineFound(regex, line, type)
            }
        }
        if (regex.fallbackRegex) {
            const regexp = createRegExp(regex.fallbackRegex)
            if (line.match(regexp)) {
                if (type === "cell") {
                    cellFileFallbackInfo.push({ info: line, severe: regex.severe });
                    setCellFileFallbackInfo(cellFileFallbackInfo);
                } else {
                    logFileFallbackInfo.push({ info: line, severe: regex.severe });
                    setLogFileFallbackInfo(logFileFallbackInfo);
                }
            }
        }
        if (!logFileData[regex.name] && !Array.isArray(logFileData[regex.name])) {
            logFileData[regex.name] = false;
        }
        setLogFileData(logFileData);
    }

    /**
     * Retrieves the information for the user defined token to be used later in the testing regex
     * @param userDefine The user defined token values. The userDefine.data should be in the format '/pattern/flags'
     * @param uniqueCellFile Contains the data of the cell file
     * @param uniqueLogFile Contains the data of the log file
     */
    function getUserDefinedTokens(userDefine, uniqueCellFile, uniqueLogFile) {
        let change = userDefine.data
        if (userDefine.isRegex) {
            const regexp = createRegExp(userDefine.data)
            uniqueLogFile.forEach(logLine => {
                if (logLine.match(regexp)) {
                    change = logLine.match(regexp)[0]
                }
            })
            uniqueCellFile.forEach(cellLine => {
                if (cellLine.match(regexp)) {
                    change = cellLine.match(regexp)[0]
                }
            })
        }
        return change
    }

    function callback(data) {
        logFileFallbackInfo.splice(0, logFileFallbackInfo.length);
        setLogFileFallbackInfo(logFileFallbackInfo);

        const uniqueLogFile = [...new Set(data.logFile)]
        const uniqueCellFile = [...new Set(data.cellFile)]
        const tokens = [
            ["<!>", "commonName"],
            ["<@>", "serialNumber"],
            ["<#>", "sims"],
            ["<%>", "imei"],
            ["<^>", "firmwareVersion"]
        ]

        const testToFormat = JSON.parse(JSON.stringify(selectedTest));
        testToFormat.regex.forEach(regex => {
            for (let i = 0; i < tokens.length; i++) {
                if (regex.regex.includes(tokens[i][0])) {
                    if (i === 2 && cameraInfoScanned.sims.length === 2) {
                        const array = [
                            regex.regex.replace("<#>", cameraInfoScanned["sims"][0]),
                            regex.regex.replace("<#>", cameraInfoScanned["sims"][1])
                        ]
                        regex.fallbackRegex = regex.regex.replace("<#>", "")
                        regex.regex = array
                    } else {
                        regex.fallbackRegex = regex.regex.replace(tokens[i][0], "")
                        regex.regex = regex.regex.replace(tokens[i][0], cameraInfoScanned[tokens[i][1]])
                    }
                }
            }
            if (regex.userDefine1 && regex.userDefine1.data !== "") {
                regex.regex = regex.regex.replace("<*>", getUserDefinedTokens(regex.userDefine1, uniqueCellFile, uniqueLogFile))
            }
            if (regex.userDefine2 && regex.userDefine2.data !== "") {
                regex.regex = regex.regex.replace("<&>", getUserDefinedTokens(regex.userDefine2, uniqueCellFile, uniqueLogFile))
            }
        })

        uniqueLogFile.forEach(logLine => {
            testToFormat.regex.forEach(regex => {
                getFileInfo(regex, logLine, "log")
            })
        })
        uniqueCellFile.forEach(cellLine => {
            testToFormat.regex.forEach(regex => {
                getFileInfo(regex, cellLine, "cell")
            })
        })
        // If the token for SIMs is used, we check for both values of SIMs in the database.
        // This code makes sure that both SIMs are found
        let updatedLogFileData = {...logFileData};
        testToFormat.regex.forEach(item => {
            if (Array.isArray(item.regex) === Array.isArray(logFileData[item.name])) {
                if (Array.isArray(item.regex)) {
                    updatedLogFileData[item.name] = logFileData[item.name].every(value => value === true);
                }
            } else {
                if (!item.fallbackRegex) {
                    updatedLogFileData[item.name] = true;
                } else {
                    if(Array.isArray(logFileData[item.name])) {
                        updatedLogFileData[item.name] = logFileData[item.name].every(value => value === true)
                    } else {
                    updatedLogFileData[item.name] = false;
                    }
                }
            }
        });

        setFormattedTest(testToFormat)
        setLogFileData(updatedLogFileData);
        setLogFileInfo(formatLogToTable(logFileInfo, logFileFallbackInfo))
        setCellFileInfo(formatLogToTable(cellFileInfo, cellFileFallbackInfo))
        setButtonDisabled(false)
        setRenderNewTest(!renderNewTest) //TODO Pas vriament une bonne facon de trigger le code... on devrait caller directement la fonction si possible, puisque ca sert juste a trigger quelque chose indrectement.
    }

    /**
     * Clears all the data from the screen and sets the focus on the scanner
     */
    function clearData() {
        setCameraInfoScanned(undefined)
        setButtonDisabled(true)
        setScanner("")
        setLogFileData([])
        setLogFileInfo([])
        setCellFileInfo([])
        setCellFileFallbackInfo([])
        setLogFileFallbackInfo([])
        setTextBoxVariable([])
        document.getElementById("scanner").select()
    }

    function clearPartialData() {
        setButtonDisabled(true)
        setLogFileData([])
        setLogFileInfo([])
        setCellFileInfo([])
        setCellFileFallbackInfo([])
        setLogFileFallbackInfo([])
        setTextBoxVariable([])
    }

    /**
     * Saves the test data to the database
     * @param {String} testStatus The status of the test (PASS or FAILED)
     */
    function onClickPutCamera(testStatus) {
        const sendData = {qualityControl : {
            testedDate: new Date().toISOString(),
            status: testStatus,
            test: selectedTest.name,
            results: {
                LogFile: logFileInfo || [],
                CellFile: cellFileInfo || []
            }
        }}
        cameraApi.putCamera(cameraInfoScanned.id, sendData)
        .then(res => {
            // if (testStatus === "PASS") {
            //     clearData()
            // }
        })
        .catch(err => {
            console.log(err)
            //clearData()
            setError("error")
        });
    }

    function onChangeScanner(e) {
        setScanner(e.target.value)
    }

    function onChangeBypassDatamatrix(e) {
        setDatamatrixBypass(e.target.checked);
    }

    const verifyRegexPassed = regex => {
        return logFileData[regex.name] || (regex.severe ? false : 1)
    }

    const handleFocus = (e) => e.target.select();

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (!sdCardFound) {
                setError(t('noSdCardDetected'))
                setButtonDisabled(true)
            } else {
                if (e.target.value === "") {
                    setError(t('dashboard_main.scan_required'))
                    setButtonDisabled(true)
                } else {
                    setError("")
                    let sn = ""
                    if (scanner.split(/,|;/).length === 4) {
                      sn = scanner.split(/,|;/)[1]
                    } else{
                        setError(t('dashboard_main.scanNotRecognized'))
                        e.target.select();
                        return;
                    }

                    cameraApi.getCamera(sn)
                    .then(res => {
                        const cameraData = res.data;
                        return cameraModelApi.getCameraModel(cameraData.cameraModel)
                            .then(modelRes => {
                                const cameraInfo = {
                                    "id": cameraData.id,
                                    "commonName": modelRes.data.commonName,
                                    "model": cameraData.cameraModel,
                                    "serialNumber": cameraData.serialNumber,
                                    "sims": cameraData.sims,
                                    "imei": cameraData.imei,
                                    "firmwareVersion": cameraData.firmwareVersion.name
                                }
                                setLogFileData([])
                                setCellFileInfo([])
                                setLogFileInfo([])
                                setCameraInfoScanned(cameraInfo)
                            });
                    })
                    .catch(err => {
                        console.log(err)
                        setButtonDisabled(true)
                        setError(t('dashboard_main.scanNotRecognized'))
                        e.target.select()
                    })
                }
            }
        }
    }

    const columns = [
        {
          Header: t('DateTime'),
          accessor: 'dateTime',
        },
        {
          Header: t('information'),
          accessor: 'info',
        },
    ]

      const columnsCell = [
        {
          Header: t('DateTime'),
          accessor: 'dateTime',
        },
        {
          Header: t('information'),
          accessor: 'info',
        },
    ]



    /**
     * Adds every tests related to a camera model as <option> for a <select>
     * If one of the tests correspond to the lastTest, it is selected
     * @returns The html containing options for all the possible tests
     */
    const addTestsToComboBox = () => {
        if (!cameraInfoScanned) return [];
        return tests
            .filter(test => test.cameraModels.includes(cameraInfoScanned.model))
            .sort((a,b)=> {
                if (a.name.startsWith('CM') && !b.name.startsWith('CM')) return -1;
                else if (!a.name.startsWith('CM') && b.name.startsWith('CM')) return 1;
                else return 0;
            })
            .map((test, index) => (
                <option key={index+1} value={test.id} selected={lastSelectedTest === test.id}>{test.name}</option>
            ));
    }

    const selectTestOnChange = (e) => {
        const test = tests.find(test => test.id === e.target.value)
        setSelectedTest(test)
        setLastSelectedTest(e.target.value)
        setLogFileData([])
        setCellFileInfo([])
        setLogFileInfo([])
        setCellFileFallbackInfo([])
        setLogFileFallbackInfo([])
    }


    return (
        <div style={{ marginTop: "30px",marginLeft: "10%", justifyContent:'center', alignItems:'center', width:'80%'}}>
            <div className="row">
                <div className="input-field left col s5">
                    <input
                        onChange={onChangeScanner}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyPress}
                        value={scanner}
                        id="scanner"
                        type="text"
                        className={classnames("", {
                            invalid: error
                        })}
                    />
                    <span id="scannerMessage" className="red-text">
                        {error}
                    </span>
                    <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                </div>
                <div className="input-field center col s5" style={{fontSize:"20px"}}>
                    {t('dashboard_main.serialNumber')}: {(cameraInfoScanned && cameraInfoScanned.serialNumber) || ""}
                </div>
                <div className="input-field right" style={{marginTop:"-15px"}}>
                    <i className={classnames("large material-icons", {
                    "green-text": sdCardFound === true,
                    "red-text": sdCardFound === false
                    })}>sd_storage</i>
                    <div style={{textAlign: "center"}} >SD Card</div>
                </div>
            </div>
            <Row>
            <Col s={12}>
                <Switch
                    id="datamatrixBypass"
                    offLabel="Datamatrix bypass OFF"
                    onChange={onChangeBypassDatamatrix}
                    onLabel="Datamatrix bypass ON"
                    checked={datamatrixBypass}
                    />
                </Col>
            </Row>
            <div className="row">
                <div className="col s3">
                    <button className="btn btn-large waves-effect waves-light col m4 hoverable green"
                        style={{
                            width:"100%",
                            maxWidth:"250px",
                            borderRadius: "3px",
                            letterSpacing: "1.5px",
                            marginRight: "2px",
                            marginLeft: "2px",
                            float: "right"
                        }}
                        disabled={buttonDisabled || !sdCardFound || !testPass}
                        // onClick={function () {onClickPutCamera("PASS")}}
                    > {t('pass')}</button>
                  </div>
                <div className="col s3">
                    <button className="btn btn-large waves-effect waves-light col m4 hoverable red accent-3"
                        style={{
                            width:"100%",
                            maxWidth:"250px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginRight: "2px",
                        marginLeft: "20px",
                        float: "right"
                        }}
                        disabled={buttonDisabled || !sdCardFound || testPass}
                        // onClick={function () {clearData()}}
                    > {t('fail')}</button>
                </div>
            </div>
            <Row>
                <Col className="center" s={7}> Test result sent automatically</Col>
            </Row>
            <div className="row">
                <select name="tests" id="tests" onChange={selectTestOnChange}>
                    <option key={0} value={0}>Select a test</option>
                    {addTestsToComboBox()}
                </select>
            </div>
            <div className="row" >
                <LabelTest textBox={textBoxVariable} />
            </div>
            <div className="row" style={{ marginBottom: "100px"}}>
                <div className="col s6">
                    <p style={{fontSize: "20px", textAlign: "center"}}>LogFile</p>
                    <ReactTableBuilder columns={columns} data={logFileInfo} maxRows={9} />
                </div>
                <div className="col s6">
                    <p style={{fontSize: "20px", textAlign: "center"}}>CellFile</p>
                    <ReactTableBuilder columns={columnsCell} data={cellFileInfo} maxRows={9} />
                </div>
            </div>
        </div>
      );
}

ProductionTesting.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });

  export default compose(
    withTranslation(),
    connect(
      mapStateToProps
    )
  )(ProductionTesting);