import React, { Component, Suspense } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
//import 'react-table/react-table.css';
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";

//Pages
import Dashboard from "./page/dashboard/Dashboard";
import UserProfile from "./page/user/UserProfile";
import Search from "./page/search/Search";
import Settings from "./page/setting/settings";
import UserSetting from "./page/setting/user";
import CameraSetting from "./page/setting/camera";
import PlantSetting from "./page/setting/plant";
//import MasterPack from "./page/inventory/index"
import NewProduct from "./page/inventory/newProduct"
import NewProductWithPO from "./page/inventory/newProductWithPO"
import NewCamera from "./page/inventory/newCamera"
import ProductStage from "./page/inventory/productStage"
import ProductSimAssociation from "./page/inventory/productSimAssociation"
import PrintColorboxLabel from "./page/inventory/printColorboxLabel"
import NewMasterPack from "./page/inventory/newMasterPack"
import PrintMasterPack from "./page/inventory/printMasterPack"
import ViewCamera from "./page/inventory/viewCamera"
import ViewMasterpack from "./page/inventory/viewMasterpack"
import Reprints from "./page/inventory/reprints"
import Shipping from "./page/inventory/shipping"
import ShippingV2 from "./page/inventory/shippingV2"
import Statistics from "./page/inventory/statistics"
import ProductionTesting from "./page/inventory/productionTesting"
import FirmwareUpdate from "./page/inventory/firmwareUpdate"
import ReportType from "./page/reporting/reporttype"
import Reporting from "./page/reporting/reporting"
import RMAInfo from "./page/inventory/rmaInfo"
import RMAReturn from "./page/inventory/rmaReturn"
import OpenRma from "./page/inventory/openRma"
import SdCard from "./page/inventory/sdCard"
import Authorization from './utils/authorization';
import ChangePassword from './page/setting/changePassword';
import ReworkChangeModel from "./page/inventory/reworkChangeModel";
import ReworkPoAndModel from "./page/inventory/reworkPoAndModel";
import manageTestQC from "./page/setting/manageTestQC";
import manageReporting from "./page/setting/manageReporting";
import ManageSimSuppliers from "./page/setting/manageSimSuppliers";
import PODetail from "./page/search/Po";

import MyNavbar from "./components/layout/MyNavbar"
import Footer from "./components/layout/Footer"
import { ROLES_FIRMWARE_UPDATE, ROLES_NEW_CAMERA, ROLES_NEW_MASTERPACK, ROLES_NEW_PRODUCT, ROLES_PRINT_COLORBOX_LABEL, ROLES_PRINT_MASTERPACK, ROLES_PRODUCT_DETAIL, ROLES_PRODUCT_SIM_ASSOCIATION, ROLES_PRODUCT_STAGE, ROLES_PRODUCTION_TESTING, ROLES_REPORTING, ROLES_REPRINTS, ROLES_REWORK_CHANGE_MODEL, ROLES_RMA_INFO, ROLES_RMA_RETURN, ROLES_OPEN_RMA, ROLES_SHIPPING, ROLES_SHIPPING_V2, ROLES_STATISTICS, ROLES_REWORK_PO_MODEL } from "./page/dashboard/card/CardInfo";

const Admin = Authorization(['Admin'])


// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/#/login";
  }
}
class App extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <Router>
            <div className="App">

              <MyNavbar brand='logo' />

              <Route exact path="/" component={Login} />
              {/* <Route exact path="/register" component={Register} /> */}
              <Route exact path="/login" component={Login} />
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/userprofile" component={UserProfile} />
                <PrivateRoute exact path="/search" component={Search} />
                <PrivateRoute exact path="/search/po" component={PODetail} />
                <PrivateRoute exact path="/settings" component={Settings}/>
                <PrivateRoute exact path="/setting/changePassword" component={ChangePassword} />
                <PrivateRoute exact path="/setting/user" component={Admin(UserSetting)} />
                <PrivateRoute exact path="/setting/camera" component={Admin(CameraSetting)} />
                <PrivateRoute exact path="/setting/plant" component={Admin(PlantSetting)} />
                <PrivateRoute exact path="/setting/manageTestQC" component={Admin(manageTestQC)} />
                <PrivateRoute exact path="/setting/reporting" component={Admin(manageReporting)} />
                <PrivateRoute exact path="/setting/manageSimSuppliers" component={Admin(ManageSimSuppliers)} />
                <PrivateRoute exact path="/inventory/newproduct" component={Authorization(ROLES_NEW_PRODUCT)(NewProduct)} />
                <PrivateRoute exact path="/inventory/newproductwithpo" component={Authorization(ROLES_NEW_PRODUCT)(NewProductWithPO)} />
                <PrivateRoute exact path="/inventory/newcamera" component={Authorization(ROLES_NEW_CAMERA)(NewCamera)} />
                <PrivateRoute exact path="/inventory/productStage" component={Authorization(ROLES_PRODUCT_STAGE)(ProductStage)} />
                <PrivateRoute exact path="/inventory/productSimAssociation" component={Authorization(ROLES_PRODUCT_SIM_ASSOCIATION)(ProductSimAssociation)} />
                <PrivateRoute exact path="/inventory/printColorboxLabel" component={Authorization(ROLES_PRINT_COLORBOX_LABEL)(PrintColorboxLabel)} />
                <PrivateRoute exact path="/inventory/printmasterpack" component={Authorization(ROLES_PRINT_MASTERPACK)(PrintMasterPack)} />
                <PrivateRoute exact path="/inventory/newmasterpack" component={Authorization(ROLES_NEW_MASTERPACK)(NewMasterPack)} />
                <PrivateRoute exact path="/inventory/reprints" component={Authorization(ROLES_REPRINTS)(Reprints)}/>
                <PrivateRoute exact path="/inventory/shipping" component={Authorization(ROLES_SHIPPING)(Shipping)}/>
                <PrivateRoute exact path="/inventory/shippingV2" component={Authorization(ROLES_SHIPPING_V2)(ShippingV2)}/>
                <PrivateRoute exact path="/inventory/statistics" component={Authorization(ROLES_STATISTICS)(Statistics)}/>
                <PrivateRoute exact path="/inventory/viewCamera/:id" component={Authorization(ROLES_PRODUCT_DETAIL)(ViewCamera)} />
                <PrivateRoute exact path="/inventory/viewCamera/" component={Authorization(ROLES_PRODUCT_DETAIL)(ViewCamera)} />
                <PrivateRoute exact path="/inventory/viewMasterpack/:id" component={Admin(ViewMasterpack)} />
                <PrivateRoute exact path="/inventory/productionTesting" component={Authorization(ROLES_PRODUCTION_TESTING)(ProductionTesting)} />
                <PrivateRoute exact path="/inventory/firmwareUpdate" component={Authorization(ROLES_FIRMWARE_UPDATE)(FirmwareUpdate)} />
                <PrivateRoute exact path="/inventory/rmaInfo" component={Authorization(ROLES_RMA_INFO)(RMAInfo)} />
                <PrivateRoute exact path="/inventory/rmaReturn" component={Authorization(ROLES_RMA_RETURN)(RMAReturn)} />
                <PrivateRoute exact path="/inventory/openRma" component={Authorization(ROLES_OPEN_RMA)(OpenRma)} />
                <PrivateRoute exact path="/inventory/sdCard" component={Authorization(ROLES_PRODUCT_DETAIL)(SdCard)} />
                <PrivateRoute exact path="/inventory/reworkChangeModel" component={Authorization(ROLES_REWORK_CHANGE_MODEL)(ReworkChangeModel)} />
                <PrivateRoute exact path="/inventory/reworkPoAndModel" component={Authorization(ROLES_REWORK_PO_MODEL)(ReworkPoAndModel)} />
                <PrivateRoute exact path="/reporting/reporttype" component={Authorization(ROLES_REPORTING)(ReportType)} />
                <PrivateRoute exact path="/reporting/reporting/:id" component={Authorization(ROLES_REPORTING)(Reporting)} />
                {/* <PrivateRoute exact path="/inventory/masterpack" component={MasterPack} /> */}
              </Switch>
              <div>
                <Footer />
              </div>
            </div>
          </Router>
        </Provider>
      </Suspense>
    );
  }
}
export default App;
