import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { connect } from "react-redux";
import classnames from "classnames";
import { formatActivationCode } from '../../utils/formatHelper';
import { isLocalPrinterReady, printDatamatrixSerialNumber, printDatamatrixFull } from '../../utils/prodlocalhelper';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraModelApi from "../../api/cameraModelApi";
import { t } from "i18next";
import { getProductPageLink } from "../../utils/site"
import { getSimLetter } from "../../utils/simSupplierUtils";
import cameraApi from "../../api/cameraApi";
import simSupplierApi from "../../api/simSupplierApi";
import poApi from "../../api/poApi";
import plantApi from "../../api/plantApi";
import jwt_decode from "jwt-decode";
import ModalFactory from "../../components/modal/ModalFactory";

class ReworkPoAndModel extends Component {
  constructor() {
    super();
    this.state = {
      scanner: "",
      poOperation:"removeFromPo",
      poFrom: "",
      poObjectFrom: {},
      poTo: "",
      poObjectTo: {},
      cameraModelFrom: "",
      cameraModelTo: "",
      camera: {},
      cameraModels: [],
      cameraModelsFrom: [],
      cameraModelsTo: [],
      pos:[],
      simSuppliers: [],
      errors: {},
      printSerialNumberLabel: false,
      printActivationLabel: false,
      printerStatus: "Loading...",
      printerName: "",
      printMessage: "",
      scannedCameras:[]
    };

    this.onSubmit = this.onSubmit.bind(this);
  };

  loadCamerasModels = e => {
    const { t } = this.props;
    cameraModelApi.getCameraModels()
      .then(res => {
        let models = res.data;
        localStorage.setItem("cameraModels", JSON.stringify(models));
        models = [{ "name": t("global.selectAModel") }, ...models]
        models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.setState({ 
          cameraModels: models, 
          cameraModelsFrom: models,
          cameraModelsTo: models 
        })
      })
      .catch(err =>
        localStorage.setItem("cameraModels", JSON.stringify([]))
      );
  }

  getSimLetter = sim => {
    return getSimLetter(sim, this.state.simSuppliers);
  }

  loadSimSuppliers = () => {
    simSupplierApi.getSimSuppliers()
      .then(res => {
        this.setState({ simSuppliers: res.data })
      })
      .catch(err =>
        this.setState({ simSuppliers: [] })
      );
  }

  loadPoFromCM = async () => {
    const plant = await this.getUserPlant();
    const cmId = plant.netsuiteCmId;
    return poApi.getOpenPoOnCm(cmId)
      .then(result => {
        if(result.data.status ==="success") {
          let pos = result.data.data.pos;
          localStorage.setItem("pos", JSON.stringify(pos));

          pos.sort((a,b) => (a.tranName > b.tranName) ? 1 : ((b.tranName > a.tranName) ? -1 : 0))

          result.data.data.pos.unshift({
            tranName: t("rework_po_and_model.select_po"),
            poId: 0,
          });
          
          this.setState({ pos })
        }
      });
  }

  loadSinglePoFromCM = async (poId) => {
    const plant = await this.getUserPlant();
    return poApi.getOpenPoOnCm(plant.netsuiteCmId, poId)
      .then(result => {
        if(result.data.status ==="success") {
          return result.data.data.pos[0];
        }
        else alert("Error");
        return undefined;
      });
  }

  filterCameraModels = async (po, cameraModelSelectId) => {
    const { t } = this.props;
    const { cameraModels } = this.state;
    let models = [{ "name": t("global.selectAModel") }];

    if(po) {
      const cameraModelsCount = await cameraApi.getCountCameraModelsByPo(po.poId);
      
      models =  [...models , ...cameraModels.filter(cm => {return !cm.masterPackOnly && cm.active})]
      models = models.filter((cm, index) => {
        const currentItem = po.items.find(i => i.sku === cm.productCode);
        if(!(currentItem || index === 0))
          return false;  

        if(cameraModelsCount && cameraModelsCount.status === 200)
        {
          const currentModelCount = cameraModelsCount.data.cams.find(i => i.productCode === cm.productCode);
          if(currentModelCount && currentModelCount.documents.length >= currentItem.quantity)
            return false;
        }
        return true;
      });
      models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    }
    
    this.setState({ [cameraModelSelectId]: models });
  }

  getUserPlant = ()=>{
    const decoded = jwt_decode(localStorage.getItem("jwtToken"));
    return plantApi.getPlant(decoded.user.plant).then(res => {
      return res.data;
    })
  }

  componentDidMount() {
    this.loadPoFromCM();
    this.loadCamerasModels();
    this.loadSimSuppliers();
    isLocalPrinterReady((err, res) => {
      if (err) {
        this.setState((state) => ({
          printerStatus: "ERROR",
          printerName: t("dashboard_main.local_printer_not_ready")
        }))
      } else {
        this.setState({
          printerStatus: res.data.status,
          printerName: res.data.name
        })
      }
    });
  }

  onChangePoOperation = (e) => {
    let { cameraModels, cameraModelsFrom, poOperation } = this.state;

    const newPoOperation = e.target.value;

    let printSerialNumberLabel= true;
    let printActivationLabel= true;

    if(newPoOperation ==="removeFromPo"){
      printSerialNumberLabel = false;
      printActivationLabel = false;
    }

    if(poOperation==="addToPo")
      cameraModelsFrom=cameraModels;

    this.setState({
      poOperation: newPoOperation,
      printMessage: "",
      cameraModelsTo: cameraModelsFrom,
      printSerialNumberLabel: printSerialNumberLabel,
      printActivationLabel: printActivationLabel
     });
  }

  handleFocus = e => e.target.select();

  handleAfterPrint = e => {
    this.setState({ items: [] });
  }

  onCameraModelFromChange = e => {
    this.setState({ cameraModelFrom: e.target.value })
  }

  onCameraModelToChange = e => {
    this.setState({ cameraModelTo: e.target.value })
  }

  handleInputChange = e => {
    if (e.target.id === "printSerialNumberLabel") {
      this.setState({ printSerialNumberLabel: !this.state.printSerialNumberLabel })
    } else if (e.target.id === "printActivationLabel") {
      this.setState({ printActivationLabel: !this.state.printActivationLabel })
    }
  }

  onChange = async (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      printMessage: ""
     });

     if(e.target.id === "poFrom" || e.target.id === "poTo"){
        const poObject = await this.loadSinglePoFromCM(e.target.value);
        let dropdownToFilter ="";
        if(e.target.id === "poTo"){
          this.setState({poObjectTo : poObject})
          dropdownToFilter ="cameraModelsTo";
        }
        else if(e.target.id === "poFrom") {
          this.setState({poObjectFrom : poObject})
          dropdownToFilter ="cameraModelsFrom";
        }
         
        await this.filterCameraModels(poObject, dropdownToFilter);
     }
  };



  printActivationLabel = () => {
    cameraModelApi.getCameraModel(this.state.camera.cameraModel)
    .then(res => {
      const data = {
        datamatrix: `${this.state.camera.activationCode},${this.state.camera.imei},${this.state.camera.serialNumber},${res.data.commonName}`.padEnd(45, '-'),
        activationCode: formatActivationCode(this.state.camera.activationCode),
        serialNumber: this.state.camera.serialNumber,
        numberOfCopies: 1
      }
      printDatamatrixFull(data, (err, res) => {
        if (err) {
          this.setState({
            printerStatus: "ERROR",
            printMessage: t('dashboard_main.print_error')
          })
        } else {
          this.setState({
            printerStatus: "ready",
            printMessage: t('dashboard_main.print_success')
          })
        }
      });
    })
    .catch(err => {
      this.setState({
        printerStatus: "ERROR",
        printMessage: t('dashboard_main.print_error')
      })
    })
  }

  printSerialNumberLabel = () => {
    let sim1, sim2;
    let indicator = [];

    if (this.state.camera.sims.length === 1) {
      sim1 = this.state.camera.sims[0]
      indicator.push(this.getSimLetter(sim1));
    } else if (this.state.camera.sims.length > 1) {
      sim1 = this.state.camera.sims[0]
      sim2 = this.state.camera.sims[1]
      indicator.push(this.getSimLetter(sim1));
      indicator.push(this.getSimLetter(sim2));
    }

    const data = {
      serialNumber: this.state.camera.serialNumber,
      datamatrix: `${this.state.camera.serialNumber},${getProductPageLink()},`.padEnd(45, '-'),
      simsIndicator: indicator,
      numberOfCopies: 1
    }
    printDatamatrixSerialNumber(data, (err, res) => {
      if (err) {
        this.setState({
          printerStatus: "ERROR",
          printMessage: t('dashboard_main.print_error')
        })
      } else {
        this.setState({
          printerStatus: "ready",
          printMessage: t('dashboard_main.print_success')
        })
      }
    });
  }

  onSubmit = e => {
    this.setState({ errors: {} })
    const { cameraModelFrom, cameraModelTo, poFrom, poTo, scanner,poOperation } = this.state;
    let valid = true;

    if(poOperation === "transferFromPo" || poOperation === "addToPo"){
      if(!cameraModelFrom || cameraModelFrom === "") {
        this.setState((state) => ({
          errors: { ...state.errors,
          cameraModelFrom: t('dashboard_main.rework_change_model_from_required')
        }}))
        valid = false;
      }
  
      if(!cameraModelTo || cameraModelTo === "") {
        this.setState((state) => ({
          errors: { ...state.errors,
          cameraModelTo: t('dashboard_main.rework_change_model_to_required')
        }}))
        valid = false;
      }

      if(!poTo || poTo === "") {
        this.setState((state) => ({
          errors: { ...state.errors,
            poTo: t('rework_po_and_model.rework_change_po_to_required')
        }}))
        valid = false;
      } 
    }

    if(poOperation === "transferFromPo" || poOperation === "removeFromPo"){
      if(!poFrom || poFrom === "") {
        this.setState((state) => ({
          errors: { ...state.errors,
            poFrom: t('rework_po_and_model.rework_change_po_from_required')
        }}))
        valid = false;
      }
    }
    

    if(!scanner || scanner === "") {
      this.setState((state) => ({
        errors: { ...state.errors,
        scanner: t('dashboard_main.scan_required')
      }}))
      valid = false;
    }

    if(valid){
      let sn = ""
      if (scanner.split(/,|;/).length === 4) {
        sn = scanner.split(/,|;/)[2]
      } else {
        sn = scanner.split(/,|;/)[0]
      }
      cameraApi.getCamera(sn).then(camera=>{
        this.doCameraUpdate(camera.data);
      });
    }
    e.preventDefault();
  };

  doCameraUpdate = async (camera)=> {
    const { cameraModels, cameraModelFrom, cameraModelTo, poFrom,poObjectFrom, poTo, poObjectTo, scanner, poOperation, scannedCameras, printSerialNumberLabel, printActivationLabel } = this.state;

    const errorArray =[];

    if(poOperation === "removeFromPo" || poOperation === "transferFromPo"){

      //Validate if current PO === poFrom
      if(camera.poNumber !== poFrom)
        errorArray.push(t("rework_po_and_model.error_originpo"))
    }
    if(poOperation === "addToPo"){
      //Validate if current PO === 0
      if(camera.poNumber !== "0")
        errorArray.push(t("rework_po_and_model.error_camera_has_po"))
    }
    if(poOperation === "addToPo" || poOperation === "transferFromPo"){
      //Validate if current model === modelFrom
      if(camera.cameraModel !== cameraModelFrom)
        errorArray.push(t("rework_po_and_model.error_model_from"))

      //Validate if modelTo is in poObjectTo (match with SKU)
      const cameraModelObject = cameraModels.find(cameraModel => cameraModel.name === cameraModelTo);

      const matchingPoItem = poObjectTo.items.filter((item) =>{
        return item.sku ===cameraModelObject.productCode;
      })
      if(matchingPoItem.length === 0)
        errorArray.push(t("rework_po_and_model.error_model_to"))
    }
  
    const scannedCamera = JSON.parse(JSON.stringify(camera));
    if(poOperation === "removeFromPo") {
      scannedCamera.poFrom = poObjectFrom.tranName;
      scannedCamera.poTo = "N/A";
    } else if(poOperation === "addToPo") {
      scannedCamera.poFrom = "N/A";
      scannedCamera.poTo = poObjectTo.tranName;
      scannedCamera.cameraModelFrom =cameraModelFrom;
      scannedCamera.cameraModelTo =cameraModelTo;
    } else if(poOperation === "transferFromPo") {
      scannedCamera.poFrom = poObjectFrom.tranName;
      scannedCamera.poTo = poObjectTo.tranName;
      scannedCamera.cameraModelFrom =cameraModelFrom;
      scannedCamera.cameraModelTo =cameraModelTo;
    }
    scannedCamera.inError = !(errorArray.length === 0)
    scannedCameras.unshift(scannedCamera);

    this.setState({ scannedCameras: scannedCameras });
   
    if(errorArray.length === 0)
    {

      if(poOperation === "removeFromPo"){
        camera.poNumber = "";
      } else if(poOperation === "addToPo" || poOperation === "transferFromPo"){
        camera.poNumber = poTo;
        camera.cameraModel = cameraModelTo;
      }
      
      //Strip footprint, not needed for update
      camera.footprints = undefined;

      const result = await cameraApi.putCamera(camera.id, camera);
      if(result.status !== 200)
       {
          alert("ERROR");
          return;
       }
      if(poOperation === "removeFromPo"){
        const footprintPoRemoved = {
          "step": "po_removed",
          "notes": "Camera removed from PO"
        }
        await cameraApi.putCameraFootprint(camera.id, footprintPoRemoved)
      }
      if(poOperation === "addToPo" || poOperation === "transferFromPo") {
        const footprintPoChanged = {
          "step": "po_changed",
          "notes": poTo
        }
        await cameraApi.putCameraFootprint(camera.id, footprintPoChanged)

        if(cameraModelFrom !== cameraModelTo) {
          const footprintReworkModel = {
            "step": "rework change model",
            "notes": "change the model of the camera"
          }
          await cameraApi.putCameraFootprint(camera.id, footprintReworkModel)
        }
      }

      if( printSerialNumberLabel)
        this.printSerialNumberLabel();
      if( printActivationLabel) 
        this.printActivationLabel();

    }
    else {
      this.setState({
        showErrorModal:true,
        errorModalMessage:errorArray.join("  ")
       });
    }
  }


  closeModalOk = () => {
    this.setState({showErrorModal:false});
  }

  render() {
    const { errors, cameraModelsFrom, cameraModelFrom, cameraModelsTo, cameraModelTo, scanner,pos, poFrom, poTo, poOperation, scannedCameras, showErrorModal, errorModalMessage, printSerialNumberLabel, printActivationLabel } = this.state;
    const { t } = this.props;
  
    return (
      <>
      <div>
        <div style={{ minHeight: "75vh", marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <Row>
                <Col s={0}>
                  <Link to="/" className="btn-flat waves-effect">
                    <i className="material-icons left" >keyboard_backspace</i> {t('userprofile.back_to_home')}
                  </Link>
                </Col>
              </Row>
              <Row>
              <Col s={12} m={3} >
                </Col>
                <Col s={12} m={6} >
                <h4>{t('rework_po_and_model.title')}</h4>
                </Col>
                <Col s={12} m={3}>
                  <i className={classnames("large material-icons", {
                    "green-text": this.state.printerStatus === "ready",
                    "red-text": this.state.printerStatus !== "ready"
                  })}>print</i>
                  <div>{this.state.printerName}</div>
                  <div>{this.state.printMessage}</div>
                </Col>
              </Row>
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <Col s={4}>
                    <label className="active" htmlFor="removeFromPo"  style={{ width: "300px", display: "inline-block" }} >{t('rework_po_and_model.removeFromPo')}</label>
                    <input type="radio" onChange={this.onChangePoOperation} name="poOperation" id="removeFromPo" checked={poOperation === "removeFromPo"} value="removeFromPo" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}}/>
                  </Col>
                </Row>
                <Row>
                <Col s={4}>
                    <label className="active" htmlFor="addToPo"  style={{ width: "300px", display: "inline-block" }} >{t('rework_po_and_model.addToPo')}</label>
                    <input type="radio" onChange={this.onChangePoOperation} name="poOperation" id="addToPo" checked={poOperation === "addToPo"} value="addToPo" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}}/>
                  </Col>
                </Row>
                <Row>
                <Col s={4}>
                    <label className="active" htmlFor="transferFromPo"  style={{ width: "300px", display: "inline-block" }} >{t('rework_po_and_model.transferFromPo')}</label>
                    <input type="radio" onChange={this.onChangePoOperation} name="poOperation" id="transferFromPo" checked={poOperation === "transferFromPo"} value="transferFromPo" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}}/>
                  </Col>
                </Row>
                <Row>
                  <div className="input-field col s6">
                    <label htmlFor="poFrom" className="active">{t('rework_po_and_model.rework_change_po_from')}</label>
                    <select type='select'
                      onChange={this.onChange}
                      disabled={poOperation==="addToPo"}
                      value={poFrom}
                      error={errors.poFrom}
                      id="poFrom"
                      className={classnames("", {
                        invalid: errors.poFrom
                      })} >
                      {pos.map((item, index) => (item.active !== false ? <option key={index} value={item.poId}>{item.tranName}</option> : ""))}
                    </select>
                    <span id="poFrom" className="red-text">
                      {errors.poFrom}
                    </span>
                  </div>
                  <div className="input-field col s6">
                    <label htmlFor="poTo" className="active">{t('rework_po_and_model.rework_change_po_to')}</label>
                    <select type='select'
                      onChange={this.onChange}
                      disabled={poOperation==="removeFromPo"}
                      value={poTo}
                      error={errors.poTo}
                      id="poTo"
                      className={classnames("", {
                        invalid: errors.poTo
                      })} >
                      {pos.map((item, index) => (item.active !== false ? <option key={index} value={item.poId}>{item.tranName}</option> : ""))}
                    </select>
                    <span id="poTo" className="red-text">
                      {errors.poTo}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div className="input-field col s6">
                    <label htmlFor="cameraModelFrom" className="active">{t('dashboard_main.rework_change_model_from')}</label>
                    <select type='select'
                      onChange={this.onCameraModelFromChange}
                      disabled={poOperation==="removeFromPo"}
                      value={cameraModelFrom}
                      error={errors.cameraModelFrom}
                      id="cameraModelFrom"
                      className={classnames("", {
                        invalid: errors.cameraModelFrom
                      })} >
                      {cameraModelsFrom.map((item, index) => (item.active !== false ? <option key={index} value={item.name}>{item.name}</option> : ""))}
                    </select>
                    <span id="cameraModelFrom" className="red-text">
                      {errors.cameraModelFrom}
                    </span>
                  </div>
                  <div className="input-field col s6">
                    <label htmlFor="cameraModelTo" className="active">{t('dashboard_main.rework_change_model_to')}</label>
                    <select type='select'
                      onChange={this.onCameraModelToChange}
                      disabled={poOperation==="removeFromPo"}
                      value={cameraModelTo}
                      error={errors.cameraModelTo}
                      id="cameraModelFrom"
                      className={classnames("", {
                        invalid: errors.cameraModel
                      })} >
                      {cameraModelsTo.map((item, index) => (item.active !== false ? <option key={index} value={item.name}>{item.name}</option> : ""))}
                    </select>
                    <span id="cameraModelTo" className="red-text">
                      {errors.cameraModelTo}
                    </span>
                  </div>
                </Row>
                <Row>
                  <Col s={0}>
                  <label className="active" htmlFor="printSerialNumberLabel"  style={{ width: "300px", display: "inline-block" }} >{t('dashboard_main.print_serial_number_label')}</label>
                  <input type="checkbox" id="printSerialNumberLabel"checked={printSerialNumberLabel === true} style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}}  onChange={this.handleInputChange}/>
                  </Col>
                </Row>
                <Row>
                  <Col s={0}>
                  <label className="active" htmlFor="printActivationLabel"  style={{ width: "300px", display: "inline-block" }} >{t('dashboard_main.print_activation_label')}</label>
                  <input type="checkbox" id="printActivationLabel" checked={printActivationLabel === true} style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}} onChange={this.handleInputChange}/>
                  </Col>
                </Row>
                <Row>
                  <div className="input-field col s8">
                    <input
                      onChange={this.onChange}
                      onFocus={this.handleFocus}
                      value={scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      autoFocus
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner" className="active">{t('dashboard_main.scan_camera')}</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Row>
              </form>
            </div>
          </div>
          <div className="itemsContent">
            <Row>
              <Col s={6}><h5>{t('dashboard_main.last_scanned_device')}</h5></Col>
              <Col s={6} className="right-align"><h5>TOTAL: {scannedCameras.filter(cam => !cam.inError).length}</h5></Col>
            </Row>
            
            <table>
              <thead>
                <tr>
                  <th>{t('inventory.newproduct.activationcode')}</th>
                  <th>{t('dashboard_main.serialNumber')}</th>
                  <th>Imei</th>
                  <th>{t('dashboard_main.camera_model_from')}</th>
                  <th>{t('dashboard_main.camera_model_to')}</th>
                  <th>{t('rework_po_and_model.camera_po_from')}</th>
                  <th>{t('rework_po_and_model.rework_change_po_to')}</th>
                </tr>
              </thead>
              <tbody>
                {scannedCameras?.map((camera, index) => 
                  {
                    return (<tr style={{color: (camera.inError?'red':'')}}>
                      <th>{formatActivationCode(camera.activationCode)}</th>
                      <th>{camera.serialNumber}</th>
                      <th>{camera.imei}</th>
                      <th>{camera.cameraModelFrom}</th>
                      <th>{camera.cameraModelTo}</th>
                      <th>{camera.poFrom}</th>
                      <th>{camera.poTo}</th>
                    </tr>)
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
     
      <ModalFactory modalMode="modalOk" buttonOk={t('ok')} onClickOk={this.closeModalOk} show={showErrorModal} title={t('error')} paragraph={errorModalMessage ?? 'Error'} />
      </>
    );
  }
}
ReworkPoAndModel.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(ReworkPoAndModel);
