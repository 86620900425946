import React, { useState } from "react";
import { Col } from "react-materialize";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import cameraModelApi from "../../../api/cameraModelApi";
import { useForm } from "react-hook-form";

import EditVersions from "./editVersions";
import ButtonFactory from "../../../components/material/ButtonFactory";
import EditMasterPackQuantities from "./editMasterPackQuantities";
import EditSimOptions from "./editSimOptions";

function EditCameraModel(props) {
  const { t } = props;
  const theYear = new Date().getFullYear()
  const [showComponentEditSimOptions, setShowComponentEditSimOptions] = useState(false)
  const [simOptions, setSimOptions] = useState(props.model.optionsSims || [])
  const [showCompEditVersion, setShowCompEditVersion] = useState(false);
  const [showComponentEditMasterPackQuantities, setshowComponentEditMasterPackQuantities] = useState(false);
  const [barcodeByMasterpack, setBarcodeByMasterpack] = useState(props.model.barcodeByMasterpack || [])
  const [cameraName, setCameraName] = useState(props.nameCamera || "");
  const [isActive, setIsActive] = useState(props.model.active || false);
  const [isMasterPackOnly, setIsMasterPackOnly] = useState(!props.model.masterPackOnly)
  const [isPack, setIsPack] = useState(props.model.isPack || false);

  function LastVersion(props) {
    let defaultVersion = "1"
    if (props.model.versions) {
      props.model.versions.map((version, index) => (
        version.stage = "default" ? defaultVersion = version.name : ""
      ));
      return defaultVersion
    }
  }

  let currentVersion = LastVersion(props)

  const { register, handleSubmit, formState: { errors } } = useForm(
    { defaultValues: {
        camera_model: props.model.commonName || '',
        sufix: props.model.nameSufix || '',
        description: props.model.masterPackDescription || '',
        barCode: props.model.barcode || '',
        cameraModel: props.model.name || '',
        year: props.model.year || '',
        version: currentVersion || '1',
        productCode: props.model.productCode || '',
        productCodeLinked: props.model.productCodeLinked || '',
        numberByPack: props.model.numberByPack || '',
      }
    });

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(data) {
    const editCameraModel = {
      name: data.cameraModel,
      commonName: data.camera_model,
      barcode: data.barCode,
      masterPackDescription: data.description,
      nameSufix: data.sufix,
      year: parseInt(data.year),
      active: isActive,
      masterPackOnly: !isMasterPackOnly,
      productCode: data.productCode,
      productCodeLinked: data.productCodeLinked,
      numberByPack: data.numberByPack,
      isPack: isPack
    };
    cameraModelApi.putCameraModel(editCameraModel)
      .then(res => {
        props.onClose();
      })
      .catch(err => {
        const errors = err.response ? err.response.data : err;
        console.log(errors)
      });
  };

  function showEditVersionWindow(e) {
    setCameraName(props.nameCamera)
    setShowCompEditVersion(!showCompEditVersion)
  };

  function showEditMasterPackQuantitiesWindow(e) {
    setCameraName(props.nameCamera)
    setshowComponentEditMasterPackQuantities(!showComponentEditMasterPackQuantities)
  };

  function handleEditMasterPackQuantitiesWindowClose(masterpackQuantities) {
    setshowComponentEditMasterPackQuantities(false)
    if (masterpackQuantities !== "cancel") {
      setBarcodeByMasterpack(masterpackQuantities)
    }
  }

  function showEditSimOptionsWindow() {
    setShowComponentEditSimOptions(!showComponentEditSimOptions)
  }

  function handleEditSimOptionsWindowClose(result) {
    setShowComponentEditSimOptions(false)
    if (result !== "cancel") {
      setSimOptions(result)
    }
  }

  function handleInputChange(e) {
    if (e.target.id === "isActive") {
      setIsActive(!isActive)
    } else if (e.target.id === "isTwinPack") {
      setIsPack(!isPack)
    } else {
      setIsMasterPackOnly(!isMasterPackOnly)
    }
  };

  return (
    <div id="editCamera" style={{ maxHeight: "95%", position: "fixed", width: "550px", backgroundColor: "#e6e6e6", borderRadius: "7px", paddingTop: "0px", marginTop: "-95px", marginLeft: " calc(50% - 730px)", zIndex: "100", overflowY:"scroll"}} className=" center-align" >
      <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.camera_edit')}
        </h3>
      </Col>
      <form name="cameraForm" id="cameraForm" onSubmit={handleSubmit(onSubmit)} >
        <Col m={4} s={12}>
          <div className="input-field">
          </div>
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="cameraModel" style={{ width: "300px", display: "inline-block" }} >{t('setting.camera_name')}</label>
          <input type="text" name="cameraModel" disabled className=" center-align " {...register("cameraModel", { required:  t('input.cameraModel_required') })} style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.cameraModel && <p className="red-text" >{errors.cameraModel.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="barCode" style={{ width: "300px", display: "inline-block" }} >{t('setting.camera_barcode')}</label>
          <select name="barCode" {...register("barCode")} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
            {barcodeByMasterpack.map((item, index) => (
              <option key={index} value={item.barcode} disabled>{item.barcode} - {item.masterpackQuantity}</option>
            ))}
          </select>
          <ButtonFactory onClick={showEditMasterPackQuantitiesWindow} name="barcode" icon='edit' style={{ float: "right", marginTop: "-42px", marginRight: "10px" }}/>
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="simOptions" style={{ width: "300px", display: "inline-block" }} >{t('setting.sim_options')}</label>
          <select name="simOptions" {...register("simOptions")} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
            {simOptions.map((item, index) => (
              <option key={index} value={item} disabled>{item}</option>
            ))}
          </select>
          <ButtonFactory onClick={showEditSimOptionsWindow} name="simOptionsEdit" icon='edit' style={{ float: "right", marginTop: "-42px", marginRight: "10px" }}/>
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="description" style={{ width: "300px", display: "inline-block" }} >{t('setting.Camera_description')}</label>
          <input type="text" name="description" {...register('description', {required: t('input.description_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.description && <p className="red-text" >{errors.description.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="camera_model" style={{ width: "300px", display: "inline-block" }} >{t('dashboard_main.camera_model')}</label>
          <input type="text" name="camera_model" {...register('camera_model', {required: t('input.camera_model_required')})} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.camera_model && <p className="red-text" >{errors.camera_model.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="productCode" style={{ width: "300px", display: "inline-block" }} >{t('setting.productCode')}</label>
          <input type="text" name="productCode"  {...register('productCode', {required: t('input.productCode_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.productCode && <p className="red-text" >{errors.productCode.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="sufix" style={{ width: "300px", display: "inline-block" }} >{t('setting.camera_sufix')}</label>
          <input type="text" name="sufix"  {...register('sufix', {required: t('input.sufix_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.sufix && <p className="red-text" >{errors.sufix.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="version" style={{ width: "300px", display: "inline-block" }} >{t('setting.version')}</label>
          <input type="text" name="version" {...register('version', {required: t('input.version_required')})} disabled className=" disabled center-align " style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          <ButtonFactory onClick={showEditVersionWindow} name="version" icon='edit' style={{ float: "right", marginTop: "-48px", marginRight: "10px" }}/>
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="year" style={{ width: "300px", display: "inline-block" }} >{t('setting.year')}</label>
          <input type="number" max={theYear + 5}  name="year"  {...register('year', {required: t('input.year_required')})} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.year && <p className="red-text" >{errors.year.message}</p>}
        </Col>
        <Col m={4} s={12}>
        <label className="active" htmlFor="masterPackOnly"  style={{ width: "300px", display: "inline-block" }} >{t('setting.masterPackOnly')}</label>
          <input type="checkbox" id="masterPackOnly" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}}  defaultChecked={isMasterPackOnly}  onClick={handleInputChange} />
        </Col>
        <Col m={4} s={12}>
        <label className="isTwinPack" htmlFor="isTwinPack"  style={{ width: "300px", display: "inline-block" }} >{t('setting.isTwinPack')}</label>
          <input type="checkbox" id="isTwinPack" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}}  defaultChecked={isPack}  onClick={handleInputChange} />
        </Col>
        {isPack &&
          <>
            <Col m={4} s={12}>
              <label className="productCodeLinked" htmlFor="productCodeLinked" style={{ width: "300px", display: "inline-block" }} >{t('setting.productCodeLinked')}</label>
              <input type="text" name="productCodeLinked"  {...register('productCodeLinked')} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
            </Col>
            <Col m={4} s={12}>
              <label className="active" htmlFor="numberByPack" style={{ width: "300px", display: "inline-block" }} >{t('setting.numberByPack')}</label>
              <input type="number" name="numberByPack"  {...register('numberByPack')} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
            </Col>
          </>
        }
        <Col m={4} s={12}>
        <label className="active" htmlFor="isActive"  style={{ width: "300px", display: "inline-block" }} >{t('setting.active')}</label>
          <input type="checkbox" id="isActive" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}}  defaultChecked={isActive}  onClick={handleInputChange} />
        </Col>
        <hr />
        <div className="row " style={{ float: "right" }}>
            <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
            <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
      </form>
      {showCompEditVersion &&
          <EditVersions
            nameCamera={cameraName}
          />
        }
        {showComponentEditMasterPackQuantities &&
          <EditMasterPackQuantities
            onClose={handleEditMasterPackQuantitiesWindowClose}
            masterPackQuantities={barcodeByMasterpack}
            cameraName={cameraName}
            cameraModels={props.cameraModels.filter(camera => camera.name !== props.model.name)}
          />
        }
        {showComponentEditSimOptions &&
          <EditSimOptions
            onClose={handleEditSimOptionsWindowClose}
            cameraName={cameraName}
            simOptions={simOptions}
          />
        }
    </div>
  );
};

EditCameraModel.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditCameraModel);
