import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal } from "react-materialize";
import ReactTableBuilder from "../../utils/reactTableBuilder";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import classnames from "classnames";
import poApi from "../../api/poApi";
import plantApi from "../../api/plantApi";
import cameraModelApi from "../../api/cameraModelApi";
import ButtonFactory from "../../components/material/ButtonFactory";
import jwt_decode from "jwt-decode";
import PoDetails from "./components/PoDetails";
import cameraApi from "../../api/cameraApi";
import cmConfigApi from "../../api/cmConfig";

class PODetail extends Component {
  constructor() {
    super();

    this.state = {
      pos: [],
      filteredPos: [],
      currentPo:{},
      poFilter: "",
      cms: [],
      cm: "",
      cmId: 0,
      errors: {},
      modalPoDetailOpen:false,
      cameraModels:[],
    };

    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.refresh = true;
      } else {
        this.refresh = false;
      }
    }
  }

  componentDidMount() {
    this.loadCameraModels();
    this.getUserPlant().then((plant) => {
      this.setState({cmId:plant.netsuiteCmId});
      this.loadPoList(plant.netsuiteCmId);
      this.loadCmList(plant.netsuiteCmId);
    });
  }

  loadPoList = (currentCmId) => {
    return poApi.getOpenPoOnCm(currentCmId).then((result) => {
      if (result.data.status === "success") {
        let pos = result.data.data.pos;

        pos.sort((a, b) =>
          a.tranName > b.tranName ? 1 : b.tranName > a.tranName ? -1 : 0
        );

        this.setState({
          pos: pos,
          filteredPos: pos,
        });
       
      }
    });
  };

  loadCmList = async (currentCmId) => {
    const cmdConfigs = await cmConfigApi.getCmConfigs();
    let cmsList = [
      {
        name: "Vosker (All)",
        id: "0",
      },
      ...(cmdConfigs.data.map(cm =>{return {
        id:cm.netsuiteInternalId,
        name:cm.name
      }}))
    ];
    if (currentCmId != 0)
      cmsList = cmsList.filter((cm) => cm.id === currentCmId);

    this.setState({
      cms: cmsList,
    });
  };

  loadCameraModels = ()=>{
    cameraModelApi.getCameraModels()
    .then(result=>{
      this.setState({cameraModels:result.data});
    })
  }

  onPoSearchKeyPress = (e) => {
    this.filterPo(e.target.value);
  };

  getUserPlant = () => {
    const decoded = jwt_decode(localStorage.getItem("jwtToken"));
    return plantApi.getPlant(decoded.user.plant).then((res) => {
      return res.data;
    });
  };

  onCmDropdownChange = (e) => {
    const { cms, poFilter } = this.state;
    debugger;
    var states = {
      [e.target.id]: e.target.value,
      cm: e.target.value,
    };
    this.setState(states);

    const currentCm = cms.find((i) => i.name === e.target.value);
    this.loadPoList(currentCm.id).then(() => {
      this.filterPo(poFilter);
    });
  };

  filterPo = (poFilter) => {
    const { pos } = this.state;
    const filteredPos = pos.filter((po) => {
      if (
        poFilter === "" ||
        po.tranName.toLowerCase().includes(poFilter.toLowerCase())
      )
        return true;

      return false;
    });

    this.setState({
      filteredPos: filteredPos,
      poFilter: poFilter,
    });
  };

  showPoDetail=(e)=>{
    const poId = e.target.id;

    return poApi.getOpenPoOnCm("0", poId)
      .then(async (result) => {
        if(result.data.status ==="success") {
          const cameraModelData = await this.getCameraModelDataForPoDetails(result.data.data.pos[0]);

          this.setState({
            currentPo: result.data.data.pos[0],
            currentPoCameraModelData:cameraModelData,
            modalPoDetailOpen: true,
          });
          return result.data.data.pos[0];
        }
        alert("Error")
      });
  }

  closePoDetail=()=>{
    this.setState({
      modalPoDetailOpen: false,
    });
  }

   getCameraModelDataForPoDetails = async (po) => {
    const {cameraModels} = this.state;

    const cameraModelsByPo = (await cameraApi.getCountCameraModelsByPo(po.poId)).data.cams;
    let cameraModelsData = cameraModels.filter(cameraModel => !cameraModelsByPo.some(cameraModelByPo => cameraModelByPo.productCode === cameraModel.productCode) && po.items.some(poItem => cameraModel.productCode === poItem.sku))
    .map(cameraModel => {
      return {
      _id:cameraModel.name,
      productCode:cameraModel.productCode,
      documents:[]
    }});
    cameraModelsData = cameraModelsData.concat(cameraModelsByPo);

    return cameraModelsData;
  }

  render() {
    const { t } = this.props;
    const { errors, filteredPos, cms, cm } = this.state;

    const columns = [
      {
        Header: t("po_detail.po_number"),
        accessor: "tranName",
      },
      {
        Header: "Action",
        accessor: (originalRow) => {
          return <ButtonFactory name={originalRow.poId} icon='remove_red_eye' onClick={this.showPoDetail}/>
        },
      },
    ];

    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <Col s={12} m={3}></Col>
                  <Col s={12} m={6}>
                    <h4>{t("po_detail.title")}</h4>
                    {/* <h6>{t("po_detail.title_desc")}</h6> */}
                  </Col>
                  <Col s={12} m={3}></Col>
                </Row>
                <Row>
                  <Col s={12} m={4}>
                    <div className="input-field">
                      <select
                        type="select"
                        label="CM"
                        onChange={this.onCmDropdownChange}
                        value={cm}
                        id="cmId"
                      >
                        {
                          // eslint-disable-next-line array-callback-return
                          cms.map((item) => {
                            return (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </Col>
                  <Col s={12} m={4}>
                    <div className="input-field">
                      <input
                        // onKeyPress={this.handleKeyPress}
                        onChange={this.onPoSearchKeyPress}
                        value={this.state.poNumber}
                        error={errors.poNumber}
                        id="poNumber"
                        type="text"
                        className={classnames("", {
                          invalid: errors.poNumber,
                        })}
                      />
                      <label htmlFor="poNumber">
                        {t("po_detail.po_number")}
                      </label>
                      <span id="poNumberMessage" className="red-text">
                        {errors.scanner}
                      </span>
                    </div>
                  </Col>
                  <Col s={12} m={4}></Col>
                </Row>
              </form>
            </div>
          </div>
          <Row>
            <ReactTableBuilder
              columns={columns}
              data={filteredPos}
              maxRows={10}
            />
          </Row>
          <PoDetails po={this.state.currentPo} isOpened={this.state.modalPoDetailOpen} onCloseStart={this.closePoDetail} cameraModelsData={this.state.currentPoCameraModelData} />
        </div>
      </div>
    );
  }
}

PODetail.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  search: state.search,
  searchResult: state.searchResult,
  errors: state.errors,
});

export default compose(withTranslation(), connect(mapStateToProps))(PODetail);
