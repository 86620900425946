import { prodapiAxiosClient } from "./prodApiClient";
import { cmList } from "../actions/staticDataList"
import cmConfigApi from "./cmConfig"

const getOpenPoOnCm = async (cmId, poId) => {
  let url = `/api/v1/netsuite/openPoOnCm/`; 

  if(cmId === "0") {
    const cmdConfigs = await cmConfigApi.getCmConfigs();
    cmId = cmdConfigs.data.filter(cm => cm.netsuiteInternalId !== "0").map(cm=>cm.netsuiteInternalId).join(",");
  }
  
  if(cmId)
    url+= `?cmId=${cmId}`;

  if(poId) {
    if(cmId)
      url += `&poId=${poId}`;
    else
      url += `?poId=${poId}`;
  }

  return prodapiAxiosClient
    .get(url)
};

export default { getOpenPoOnCm }