import React from "react";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import plantApi from "../../../api/plantApi";
import { useForm } from "react-hook-form";
import ButtonFactory from "../../../components/material/ButtonFactory";
import { Row, Col } from "react-materialize";

function EditPlant(props) {
  const { t } = props;
  const { register, handleSubmit, formState: { errors } } = useForm(
    {
    defaultValues: {
      plantName: props.plant.name,
      plantCountry: props.plant.country,
      plantCity: props.plant.city,
      plantSn: props.plant.serialNumber,
      numberOfCopies: props.plant.numberOfCopies,
      viewAll: props.plant.viewAll,
      plantNetsuiteCmId: props.plant.netsuiteCmId
    }
  });

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(info) {

    const data = {
      "name": info.plantName,
      "country": info.plantCountry,
      "city": info.plantCity,
      "serialNumber": info.plantSn,
      "isActive": true,
      "numberOfCopies" : info.numberOfCopies,
      "viewAll": info.viewAll,
      "netsuiteCmId": info.plantNetsuiteCmId
    }

    plantApi.putPlant(props.plantID, data)
      .then(res => {
        props.onClose();
      })
      .catch(err => {
        const errors = err.response ? err.response.data : err;
        console.log(errors)
      });
  }


  return (
    <div >
        <div id="editPlant" style={{ position: "fixed", width: "550px", backgroundColor: "#e6e6e6", borderRadius: "7px", paddingTop: "0px", marginTop: "-95px", marginLeft: " calc(50% - 730px)", zIndex:"999"  }} className="container center-align">
          <h3 className="center-align defaultTheme white-text" style={{ maringTop: "50px" }} >
            {t('setting.edit_plant')}
          </h3>
          <form name="plantEditForm" id="plantEditForm"  onSubmit={handleSubmit(onSubmit)}>
            <Row >
              <label className="active" htmlFor="plantName" style={{ width: "100px", display: "inline-block" }}>{t('plant.plant_name')}</label>
              <input type="text" name="plantName" className=" center-align " {...register('plantName', {required: t('input.plantName')})} style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
              {errors.plantName  && <p className="red-text" >{errors.plantName.message}</p>}
            </Row>
            <Row>
              <label className="active" id="plantCountry" style={{ width: "100px", display: "inline-block" }}>{t('plant.plant_country')}</label>
              <input type="text" name="plantCountry" className=" center-align " {...register('plantCountry', {required: t('input.plantCountry')})} style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
              {errors.plantCountry  && <p className="red-text" >{errors.plantCountry.message}</p>}
            </Row>
            <Row >
              <label className="active" htmlFor="plantCity" style={{ width: "100px", display: "inline-block" }}>{t('plant.plant_city')}</label>
              <input type="text" name="plantCity" className=" center-align " {...register('plantCity', {required: t('input.plantCity')})} style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
              {errors.plantCity  && <p className="red-text" >{errors.plantCity.message}</p>}
            </Row>
            <Row >
              <label className="active" htmlFor="plantNetsuiteCmId" style={{ width: "100px", display: "inline-block" }}>{t('plant.plant_netsuitecmid')}</label>
              <input type="text" name="plantNetsuiteCmId" className=" center-align " {...register('plantNetsuiteCmId', {required: t('input.plantNetsuiteCmId')})} style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
              {errors.plantNetsuiteCmId  && <p className="red-text" >{errors.plantNetsuiteCmId.message}</p>}
            </Row>
            <Row >
              <Col m={6} s={6}>
                <label className="active left-align" htmlFor="plantSn" style={{ width: "650px", display: "inline-block", marginLeft: "10%" }}>{t('plant.plant_sn_identity')}</label>
                <input type="text" name="plantSn" className=" center-align " {...register('plantSn', {required: t('input.plantSn')})} style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "0%", marginRight: "10%", width: "20%" }} />
                {errors.plantSn  && <p className="red-text" >{errors.plantSn.message}</p>}
              </Col>
              <Col m={6} s={6}>
                <label className="active left-align" htmlFor="numberOfCopies" style={{ width: "650px", display: "inline-block", marginLeft: "10%" }}>{t('numberOfCopies')}</label>
                <input type="number" name="numberOfCopies" className=" center-align " {...register('numberOfCopies', {required: t('numberOfCopies')})} style={{ backgroundColor: " #e6ffff", borderRadius: "7px", marginLeft: "0%", marginRight: "10%", width: "20%" }} />
                {errors.plantSn  && <p className="red-text" >{errors.plantSn.message}</p>}
              </Col>
            </Row>
            <Row style={{ display: "flex", alignItems: "center", marginTop: "2%", marginRight: "2%" }}>
              <label className="active" htmlFor="viewAll" style={{ marginLeft: "10%" }}>View All Camera</label>
              <input name="viewAll" type="checkbox" id="viewAll" style={{ backgroundColor: "#ffffff", opacity: "100", pointerEvents: "visible", height: "25px", width: "25px", marginLeft: "30%" }}  defaultChecked={false} {...register('viewAll')} />
            </Row>
            <hr />
            <div className="row " style={{ float: "right" }}>
            <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
            <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
            </div>
          </form>
        </div>
    </div>
  );
}
EditPlant.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});





export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditPlant);