import React from 'react';
import { Modal, Collapsible, CollapsibleItem, Icon } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTableBuilder from "../../../utils/reactTableBuilder";


function PoDetails(props) {
  const { t, po, isOpened, onCloseStart, cameraModelsData } = props;

  return (
    <Modal
      open={isOpened}
      header={po.tranName}
      options={{
        onCloseStart: onCloseStart,
      }}
      className="large-modal"
    >
      <TableDetails t={t} po={po} cameraModelsData={cameraModelsData}/>
    </Modal>
  );
}

function TableDetails(props){
  const { t, po, cameraModelsData } = props;

  const columns = [
    {
      Header: t("dashboard_main.camera_model"),
      accessor: "model",
    },
    {
      Header: t("po_detail.creation"),
      accessor: "creation_qty",
      maxWidth: 100,
    },
    {
      Header: t("po_detail.packed"),
      accessor: "packed_qty",
      maxWidth: 100,
    },
    {
      Header: t("po_detail.palet"),
      accessor: "palet_qty",
      maxWidth: 100,
    },
    {
      Header: t("po_detail.shipped"),
      accessor: "shipped_qty",
      maxWidth: 100,
    },
    {
      Header: t("Total"),
      accessor: (row) => {
        return row.shipped_qty + row.palet_qty + row.packed_qty + row.creation_qty;
      },
      maxWidth: 100,
    },
    {
      Header: t("po_detail.required"),
      accessor: "required_qty",
      getCellProps: () => ({
        className: 'greyed-out-column',
      }),
      maxWidth: 100,
    },
    {
      Header: t("po_detail.removed"),
      accessor: "removed_qty",
      maxWidth: 100,
    },
  ];

  const computedRows = cameraModelsData?.map(cameraModel =>
  {
    const row = {};

    const poItem = po.items.find(item => item.sku === cameraModel.productCode);
    row.model = cameraModel._id;
    row.required_qty = poItem?.quantity;
    row.creation_qty = getCameraCountForStatus(cameraModel.documents,"creation", po)
    row.completed_qty = getCameraCountForStatus(cameraModel.documents,"completed", po)
    row.packed_qty =getCameraCountForStatus(cameraModel.documents,"packed", po)
    row.palet_qty = getCameraCountForStatus(cameraModel.documents,"palet", po)
    row.shipped_qty = getCameraCountForStatus(cameraModel.documents,"shipped", po)
    row.removed_qty = getCameraCountForStatus(cameraModel.documents,"removed", po)

    return row;
  });

  return <ReactTableBuilder
                columns={columns}
                data={computedRows ?? []}
              />;
}

function getCameraCountForStatus(cameras, status, po) {
  return cameras.filter((camera) => {
    let isOfStatus = false;
    let deleteFoundLookForLastPO = false;
    for (let index = camera?.footprints.length-1; 0 <= index; index--) {
      const footprint = camera.footprints[index];
      if(status === "creation") {
        if(footprint.step === "packaging" || footprint.step === "removed" || footprint.step === "packaging" || footprint.step === "palet" || footprint.step === "shipping")
          break;
        else if(footprint.step ==="creation") {
          isOfStatus = true;
          break;
        }
      } else if(status === "packed" && footprint.step ==="packaging"){
        if(footprint.step === "creation" || footprint.step === "removed" || footprint.step === "packaging" || footprint.step === "palet" || footprint.step === "shipping")
          break;
        else if(footprint.step ==="packaging") {
          isOfStatus = true;
          break;
        }
      } else if(status === "removed") {
        if(camera.poNumber !== po.poId) //IF poId don't match it means this camera was returned because at some point it did belong to this PO, so we can safely assume its been removed or deleted at some point.
        {
          isOfStatus = true;
          break;
        } 
      } else if(deleteFoundLookForLastPO && status === "removed" && footprint.step ==="po_changed" && footprint.note === po.poId){
        isOfStatus = true;
        break;
      } else if(status === "palet" && footprint.step ==="palet"){
        if(footprint.step === "creation" || footprint.step === "removed" || footprint.step === "packaging" || footprint.step === "packaging" || footprint.step === "shipping")
          break;
        else if(footprint.step ==="palet") {
          isOfStatus = true;
          break;
        }
      } else if(status === "shipped" && footprint.step ==="shipping"){
        if(footprint.step === "creation" || footprint.step === "removed" || footprint.step === "packaging" || footprint.step === "packaging" || footprint.step === "palet")
          break;
        else if(footprint.step ==="shipping") {
          isOfStatus = true;
          break;
        }
      }
    }
    return isOfStatus;
  }).length;
}

PoDetails.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  
  export default compose(
    withTranslation(),
    connect(
      mapStateToProps,
    )
)(PoDetails);
