import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Row, Col } from "react-materialize";
import classnames from "classnames";
import "../../App.css";
import ButtonFactory from "../../components/material/ButtonFactory"
import cameraApi from "../../api/cameraApi"
import cameraModelApi from "../../api/cameraModelApi"
import rmaInfoApi from "../../api/rmaInfoApi"
import ReactTableBuilder from "../../utils/reactTableBuilder";

const handleFocus = (event) => event.target.select();
function OpenRma (props) {
    const { t } = props;
    const ref = useRef(null);
    const [scanner, setScanner] = useState("");
    const [errors, setErrors] = useState("");
    const [snackBar, setSnackBar] = useState([]);
    const [snackBarError, setSnackBarError] = useState([]);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [rmaList, setRmaList] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);
    const [camTable, setCamTable] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorRmaNotFound, setErrorRmaNotFound] = useState(false);

    // Dropdown search
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [isFocused, setIsFocused] = useState(false);
    const [selectedOpenRma, setSelectedOpenRma] = useState('');

    const filteredOptions = rmaList
        .filter(rma =>
            `${rma.name} (${rma.country})`.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.country.localeCompare(b.country);
            } else {
                return b.country.localeCompare(a.country);
            }
        });

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdownContainer = document.querySelector('.dropdown-container');
            if (isDropdownOpen && dropdownContainer && !dropdownContainer.contains(event.target)) {
                setIsDropdownOpen(false);
                setIsFocused(false);
                setSearchTerm(selectedOpenRma);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen, selectedOpenRma]);

    useEffect(() => {
      if (selectedOpenRma && camTable.length > 0) {
          setIsDisabled(false);
      } else {
          setIsDisabled(true);
      }
  }, [selectedOpenRma, camTable]);


    useEffect(() => {
      fetchRmaList()
    }, []);

      const columns = [
        {
          Header: t('dashboard_main.serialNumber'),
          accessor: 'serialNumber',
        },
        {
          Header: t('dashboard_main.camera_model'),
          accessor: 'cameraModel',
        },
        {
          Header: t('product_code'),
          accessor: 'productCode',
        },
        {
          Header: t('Remove Camera'),
          id: 'delete',
          accessor: 'delete',
          Cell: ({ row }) => (
            <div style={{ textAlign: "center" }}>
              <ButtonFactory icon='delete'
                onClick={() => {
                    const newArray = [...camTable]
                    newArray.splice(row.index, 1)
                    setCamTable(newArray)
                }}
              />
            </div>
          ),
        },
      ]

    const openSnackBar = (text, isSuccess) => {
      const newSnackBar = [];
      newSnackBar.push({text: text})
      if (isSuccess === true) {
        setSnackBar(newSnackBar);
      } else {
        setSnackBarError(newSnackBar);
      }
      setTimeout(() => {
        const emptyArray = [];
        setSnackBar(emptyArray);
        setSnackBarError(emptyArray);
      }, 3000);
    };

      function onChange(e) {
        setScanner(e.target.value);
      };

      function handleKeyPress(e) {
        if (e.key === 'Enter' && e.target.value !== "") {
            if (isValidDataCamera()) {
              setErrors("")
              cameraApi.getCamera(takeOnlyIMEI()).then(res => {
                  cameraModelApi.getCameraModel(res.data.cameraModel).then(res2 => {
                    if(res2.data.productCode) {
                        if (camTable.filter(obj => obj.serialNumber === res.data.serialNumber).length > 0) {
                            openSnackBar(t("already_in_table"), false)
                        } else {
                            if (res2.data.isPack) {
                              res2.data.productCode = res2.data.productCodeLinked
                            }
                            setCamTable([...camTable, {id: res.data.id, productCode: res2.data.productCode, serialNumber: res.data.serialNumber, cameraModel: res.data.cameraModel}])
                            openSnackBar(t("get_camera_success"), true)
                        }
                    } else {
                        openSnackBar(t("error_no_product_code"), false)
                    }
                  }).catch(err => {
                    openSnackBar(t("error_get_cam_model"), false)
                  })
                }
              ).catch(err => {
                openSnackBar(t("get_camera_failed"), false)
              })
            }
             else {
                setErrors(t("error_format"))
            }
          }
      }

      function takeOnlyIMEI() {

        var simNumber20 = new RegExp(/\d{20}/).exec(scanner);
        if (simNumber20) {
          return simNumber20[0]
        }
        var simNumber = new RegExp(/\d{19}/).exec(scanner);
        if (simNumber) {
          return simNumber[0]
        }

        var imeiNumber = new RegExp(/\d{15}/).exec(scanner);
        if (imeiNumber) {
          return imeiNumber[0]
        }
      }

      /**
      * Checks if the input scanner data contains a sequence of exactly 15 or 19 consecutive digits.
      *
      * @returns {boolean} Returns true if the scanner data contains either a 15-digit or a 19-digit number, otherwise returns false.
      */
      function isValidDataCamera() {
        var returnValue = false;
          if ((new RegExp(/\d{15}/).test(scanner)) || (new RegExp(/\d{19}/).test(scanner))) {
            return true;
          }

          return returnValue;
      }



      function resetPage() {
        setSelectedOpenRma("")
        setSearchTerm("")
        setSelectedRowId("")
        setCamTable([])
        setScanner("")
        setErrorRmaNotFound(false)
        ref.current.focus();
      }

      function updateFootprints() {
        setIsLoading(true)
        saveFootPrint()
      }

      async function saveFootPrint() {
        const newStage = {
          step: "RMA Return",
          notes: selectedOpenRma
        }

      var isSuccess = await saveAllFootPrint(newStage);
      if (isSuccess) {
        setIsLoading(false)
        resetPage()
        openSnackBar(t("RMA_saved_successfully"), true)
      } else {
        setIsLoading(false)
        resetPage()
        openSnackBar(t("save_camera_footPrints_failed"), false)
      }
    }

    const saveAllFootPrint = async (newStage) => {
        return await Promise.all(camTable.map(async camera => {
          try {
            return await cameraApi.putCameraFootprint(camera.id, newStage)
          } catch (err) {
            openSnackBar(t("save_camera_footPrints_failed"), false)
          }
        }));

      return true
    }

  function handleRmaChange(e) {
    setSelectedOpenRma(e.target.value);
    const selectedRmaData = rmaList.find(rma => rma.id === e.target.value);
    if (selectedRmaData) {
      setSelectedRowId(selectedRmaData.id);
    }
  }

  async function fetchRmaList() {
    try {
        setIsLoading(true);
        const response = await rmaInfoApi.getOpenRmaNetsuite();
        const processedResponse = response.map(rma => ({
            ...rma,
            id: `${rma.netsuiteId}-${rma.country}`
        }));
        setRmaList(processedResponse);
        setErrorRmaNotFound(processedResponse.length === 0);
    } catch (error) {
        console.error("Error fetching RMA list", error);
        setErrorRmaNotFound(true);
    } finally {
        setIsLoading(false);
    }
}

  return (
    <div>
        <Row className="center-align">
            <Col s={12}>
                <h4>{t('inventory.openRMA.title')}</h4>
                <h6>{t('inventory.openRMA.update')}</h6>
            </Col>
        </Row>
        <div style={{ marginTop: "10px" }} className="container">
            <div className={isLoading ? 'parentDisable' : ''} width="100%">
                <div className={isLoading ? 'loader' : ''}></div>
            </div>
             <div className="row">
                {/* Scanner Input and RMA Display */}
                <div className="col s12" style={{ marginTop: "20px" }}>
                    <Row>
                        <Col s={6}>
                            <div className="input-field">
                                <input
                                    disabled={isLoading}
                                    onChange={onChange}
                                    onFocus={handleFocus}
                                    onKeyPress={handleKeyPress}
                                    value={scanner}
                                    error={errors.scanner}
                                    id="scanner"
                                    type="text"
                                    autoFocus
                                    className={classnames("", {
                                        invalid: errors.scanner
                                    })}
                                    ref={ref}
                                />
                                <label htmlFor="scanner" className="active">{t('Scan (scan or number + enter)')}</label>
                                <span id="scannerMessage" className="red-text">
                                    {errors.scanner}
                                </span>
                            </div>
                        </Col>
                        <Col s={6} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                            <div style={{ width: "100%", marginBottom: "15px", position: "relative" }} className="dropdown-container">
                                <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onFocus={() => {
                                            setIsDropdownOpen(true);
                                            setIsFocused(true);
                                        }}
                                        placeholder={t('inventory.openRMA.select_rma')}
                                        className="browser-default"
                                        style={{
                                            width: "100%",
                                            padding: "8px 12px",
                                            paddingRight: "60px",
                                            backgroundColor: "white",
                                            border: "1px solid #ddd",
                                            borderRadius: "2px"
                                        }}
                                    />
                                    {isFocused && (
                                        <div style={{
                                            position: "absolute",
                                            right: "8px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "20px"
                                        }}>
                                            <button
                                                onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                                                style={{
                                                    cursor: "pointer",
                                                    border: "none",
                                                    background: "none",
                                                    padding: "2px",
                                                    color: '#26a69a',
                                                    fontSize: "20px"
                                                }}
                                            >
                                                {sortOrder === 'asc' ? '🔼' : '🔽'}
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {isDropdownOpen && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "100%",
                                            left: 0,
                                            right: 0,
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                            backgroundColor: "#424242",
                                            borderRadius: "4px",
                                            zIndex: 1000,
                                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                            marginTop: "2px"
                                        }}
                                    >
                                        {filteredOptions.length > 0 ? (
                                            filteredOptions.map((rma) => (
                                                <div
                                                    key={rma.id}
                                                    onClick={() => {
                                                        handleRmaChange({ target: { value: rma.id } });
                                                        setSelectedRowId(rma.id);
                                                        const newValue = `${rma.name} (${rma.country})`;
                                                        setSearchTerm(newValue);
                                                        setSelectedOpenRma(rma.openRma);
                                                        setIsDropdownOpen(false);
                                                        setIsFocused(false);
                                                    }}
                                                    style={{
                                                        padding: "10px 12px",
                                                        cursor: "pointer",
                                                        color: "white",
                                                        fontSize: "14px",
                                                        borderBottom: "1px solid rgba(255,255,255,0.1)",
                                                        transition: "background-color 0.2s ease",
                                                        backgroundColor: `${rma.name} (${rma.country})` === selectedOpenRma ? "#616161" : "transparent"
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = "#616161";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = `${rma.name} (${rma.country})` === selectedOpenRma ? "#616161" : "transparent";
                                                    }}
                                                >
                                                    {`${rma.name} (${rma.country})`}
                                                </div>
                                            ))
                                        ) : (
                                            <div style={{ padding: "10px 12px", color: "white", fontSize: "14px", textAlign: "center" }}>
                                                {t('No RMA found')}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ fontWeight: "bold", marginRight: "10px" }}>RMA# : </span>
                                <span>
                                    {selectedRowId ?
                                        `${rmaList.find(rma => rma.id === selectedRowId)?.openRma}`
                                        : "..."
                                    }
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Rest of your existing components */}
                <div className="col s12">
                    <Row>
                        <ReactTableBuilder columns={columns} data={camTable} maxRows={20} />
                    </Row>
                    {/* Notes and Buttons */}
                    <Row>
                        <Col s={4}>
                        </Col>
                        <ButtonFactory
                            disabled={isLoading}
                            name="Reset"
                            text={t('reset')}
                            style={{
                                width:"30%",
                                marginTop: "2%",
                                marginRight: "50px",
                                backgroundColor: "red"
                            }}
                            icon='edit'
                            onClick={resetPage}
                        />
                        <ButtonFactory
                            disabled={isDisabled || isLoading}
                            name="Save"
                            text={t('dashboard_main.save')}
                            style={{marginTop: "2%", width:"30%"}}
                            icon='edit'
                            onClick={updateFootprints}
                        />
                    </Row>
                </div>
            </div>

            {/* Snackbars */}
            <div className={snackBarError.length > 0 ? ['snackbarError', 'show'].join(" ") : 'snackbarError'}>
                {snackBarError.length > 0 ? snackBarError[0].text : ''}
            </div>
            <div className={snackBar.length > 0 ? ['snackbar', 'show'].join(" ") : 'snackbar'}>
                {snackBar.length > 0 ? snackBar[0].text : ''}
            </div>
        </div>
    </div>
);
}

OpenRma.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });

  export default compose(
    withTranslation(),
    connect(
      mapStateToProps
    )
  )(OpenRma);