
import { useTranslation } from 'react-i18next';
export const ROLES_NEW_PRODUCT = ['Admin', 'Production', 'Manager', 'Student', 'Production CM', 'Manager CM'];
export const ROLES_NEW_PRODUCT_WITH_PO = ['Admin'];
export const ROLES_PRODUCT_STAGE = ['Admin', 'Production', 'Manager', 'Student', 'Return'];
export const ROLES_PRODUCT_SIM_ASSOCIATION = ['Admin', 'Production', 'Manager', 'Student', 'Production CM', 'Manager CM'];
export const ROLES_PRINT_COLORBOX_LABEL = ['Admin', 'Production', 'Manager', 'Student', 'Production CM', 'Manager CM'];
export const ROLES_NEW_MASTERPACK = ['Admin', 'Production', 'Manager', 'Shipping', '3pl', 'Student', 'Production CM', 'Shipping CM', 'Manager CM'];
export const ROLES_PRINT_MASTERPACK = ['Admin', 'Production', 'Manager', 'Shipping', '3pl', 'Student', 'Production CM', 'Shipping CM', 'Manager CM'];
export const ROLES_STATISTICS = ['Admin'];
export const ROLES_REPORTING = ['Admin', 'Manager', 'Production', 'Shipping', '3pl', 'Return', 'Student', 'Shipping CM', 'Production CM', 'Manager CM', 'Reporting'];
export const ROLES_SETTINGS = ['Admin'];
export const ROLES_REPRINTS = ['Admin', 'Production', 'Manager', 'Shipping', '3pl', 'Student', 'Production CM', 'Shipping CM', 'Manager CM'];
export const ROLES_NEW_CAMERA = ['Admin'];
export const ROLES_SHIPPING_V2 = ['Admin', 'Shipping', '3pl', 'Shipping CM'];
export const ROLES_PRODUCTION_TESTING = ['Admin', 'Production', 'Manager', 'Student', 'Production CM', 'Manager CM'];
export const ROLES_PRODUCT_DETAIL = ['Admin', 'Production', 'Manager', 'Student', 'Return', 'Manager CM'];
export const ROLES_FIRMWARE_UPDATE = ['Admin'];
export const ROLES_RMA_INFO = ['Admin'];
export const ROLES_REWORK_CHANGE_MODEL = ['Admin', 'Production', 'Student', 'Manager', 'Manager CM'];
export const ROLES_REWORK_PO_MODEL = ['Admin']; // ,'Manager', 'Manager CM'
export const ROLES_RMA_RETURN = ['Admin', 'Shipping', 'Return'];
export const ROLES_OPEN_RMA = ['Admin'];
export const ROLES_SHIPPING = ['Admin'];
export const ROLES_PO_DETAIL = ['Admin',]; //'Manager','Manager CM'

function CardInfo () {
    const { t } = useTranslation();

    return [
       
        {
            idCard:"newProduct",
            toLink:`/inventory/newproduct`,
            keyCard:"dbnp_1",
            textSpan:t('dashboard_main.new_product'),
            textAction:t('dashboard_main.new_product'),
            text:t('dashboard_main.new_product_desc'),
            roles:ROLES_NEW_PRODUCT,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"newProductWithPO",
            toLink:`/inventory/newproductwithpo`,
            keyCard:"dbnp_1",
            textSpan:t('dashboard_main.new_product_with_po'),
            textAction:t('dashboard_main.new_product_with_po'),
            text:t('dashboard_main.new_product_desc'),
            roles:ROLES_NEW_PRODUCT_WITH_PO,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"productStage",
            toLink:'/inventory/productStage',
            keyCard:"dbps_1",
            textSpan:t('dashboard_main.product_stage'),
            textAction:t('dashboard_main.product_stage'),
            text:t('dashboard_main.product_stage_desc'),
            roles:ROLES_PRODUCT_STAGE,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"productSimAssociation",
            toLink:`/inventory/productSimAssociation`,
            keyCard:"dbpas_1",
            textSpan:t('dashboard_main.product_sim'),
            textAction:t('dashboard_main.product_sim'),
            text:t('dashboard_main.product_sim_desc'),
            roles:ROLES_PRODUCT_SIM_ASSOCIATION,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"printColorboxLabel",
            toLink:`/inventory/printColorboxLabel`,
            keyCard:"dbocl_1",
            textSpan:t('dashboard_main.print_colorbox_label'),
            textAction:t('dashboard_main.print_colorbox_label'),
            text:t('dashboard_main.print_colorbox_label_desc'),
            roles:ROLES_PRINT_COLORBOX_LABEL,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"newMasterpack",
            toLink:`/inventory/newmasterpack`,
            keyCard:"db2_1",
            textSpan:t('dashboard_main.new_masterpack_title'),
            textAction:t('dashboard_main.new_masterpack_title'),
            text:t('dashboard_main.build_masterpack'),
            roles:ROLES_NEW_MASTERPACK,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"printMasterPack",
            toLink:`/inventory/printmasterpack`,
            keyCard:"db3_2",
            textSpan:t('dashboard_main.print_masterpack_title'),
            textAction:t('dashboard_main.print_masterpack_title'),
            text:t('dashboard_main.print_masterpack'),
            roles:ROLES_PRINT_MASTERPACK,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"poDetail",
            toLink:'/search/po',
            keyCard:"dbps_1",
            textSpan:t('po_detail.title'),
            textAction:t('po_detail.card_action'),
            text:t('po_detail.description'),
            roles:ROLES_PO_DETAIL,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"statistics",
            toLink:`/inventory/statistics`,
            keyCard:"dbs_1",
            textSpan:t('dashboard_main.view_statistics_title'),
            textAction:t('dashboard_main.view_statistics_action'),
            text:t('dashboard_main.view_statistics'),
            roles:ROLES_STATISTICS,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"reporting",
            toLink:`/reporting/reporttype`,
            keyCard:"dbs_1",
            textSpan:t('dashboard_main.reporting'),
            textAction:t('dashboard_main.reporting'),
            text:t('dashboard_main.create_report'),
            roles:ROLES_REPORTING,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"settings",
            toLink:`/settings`,
            keyCard:"dbms_1",
            textSpan:t('dashboard_main.manage_settings_title'),
            textAction:t('dashboard_main.manage_settings_action'),
            text:t('dashboard_main.manage_settings'),
            roles:ROLES_SETTINGS,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"reprints",
            toLink:`/inventory/reprints`,
            keyCard:"dbpt_1",
            textSpan:t('dashboard_main.print'),
            textAction:t('dashboard_main.print'),
            text:t('dashboard_main.reprint_barcode'),
            roles:ROLES_REPRINTS,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"newCamera",
            toLink:`/inventory/newcamera`,
            keyCard:"dbnp_2",
            textSpan:t('dashboard_main.new_camera_desc'),
            textAction:t('dashboard_main.new_camera_title'),
            text:t('dashboard_main.new_camera_title'),
            roles:ROLES_NEW_CAMERA,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"ShippingV2",
            toLink:`/inventory/ShippingV2`,
            keyCard:"ShippingV2",
            textSpan:t('dashboard_main.shipment'),
            textAction:t('dashboard_main.shipment'),
            text:t('dashboard_main.shipment_desc'),
            roles:ROLES_SHIPPING_V2,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"productionTesting",
            toLink:`/inventory/productionTesting`,
            keyCard:"dbpt_2",
            textSpan:t('dashboard_main.production_testing'),
            textAction:t('dashboard_main.production_testing_action'),
            text:t('dashboard_main.production_testing_desc'),
            roles:ROLES_PRODUCTION_TESTING,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"productDetail",
            toLink:`/inventory/viewCamera`,
            keyCard:"dbpd_1",
            textSpan:t('dashboard_main.productDetail'),
            textAction:t('dashboard_main.productDetail'),
            text:t('dashboard_main.productDetail_desc'),
            roles:ROLES_PRODUCT_DETAIL,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"firmwareUpdate",
            toLink:`/inventory/firmwareUpdate`,
            keyCard:"dbfu",
            textSpan:t('dashboard_main.firmwareUpdate'),
            textAction:t('dashboard_main.firmwareUpdate'),
            text:t('dashboard_main.firmwareUpdate_desc'),
            roles:ROLES_FIRMWARE_UPDATE,
            sites:["vosker"]
        },
        {
            idCard:"rmaInfo",
            toLink:`/inventory/rmaInfo`,
            keyCard:"dbrma",
            textSpan:t('dashboard_main.rmaInfo'),
            textAction:t('dashboard_main.rmaInfo_action'),
            text:t('dashboard_main.rmaInfo_desc'),
            roles:ROLES_RMA_INFO,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"reworkChangeModel",
            toLink:`/inventory/reworkChangeModel`,
            keyCard:"dbrcm",
            textSpan:t('dashboard_main.rework_change_model_title'),
            textAction:t('dashboard_main.rework_change_model_title'),
            text:t('dashboard_main.rework_change_model'),
            roles:ROLES_REWORK_CHANGE_MODEL,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"reworkChangeModel",
            toLink:`/inventory/reworkPoAndModel`,
            keyCard:"dbrcm",
            textSpan:t('rework_po_and_model.title'),
            textAction:t('rework_po_and_model.title'),
            text:t('rework_po_and_model.description'),
            roles:ROLES_REWORK_PO_MODEL,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"rmaReturn",
            toLink:`/inventory/rmaReturn`,
            keyCard:"rmaReturn",
            textSpan:t('dashboard_main.rmaReturn'),
            textAction:t('dashboard_main.rmaReturn_action'),
            text:t('dashboard_main.rmaReturn_action'),
            roles:ROLES_RMA_RETURN,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"openRma",
            toLink:`/inventory/openRma`,
            keyCard:"openRma",
            textSpan:t('dashboard_main.openRma'),
            textAction:t('dashboard_main.openRma_action'),
            text:t('dashboard_main.openRma_action'),
            roles:ROLES_OPEN_RMA,
            sites:["spypoint", "vosker"]
        },
        {
            idCard:"shipping",
            toLink:`/inventory/shipping`,
            keyCard:"dbsp_1",
            textSpan:t('dashboard_main.shipment_deprecated'),
            textAction:t('dashboard_main.shipment_deprecated'),
            text:t('dashboard_main.shipment_desc_deprecated'),
            roles:ROLES_SHIPPING,
            sites:["spypoint", "vosker"]
        },
    ];
}
export default CardInfo;